import { LoadingButton } from '@mui/lab';
import { Fade, Stack, Typography, Box } from '@mui/material';
import { useState, useCallback, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets/assets';
import { smeStyles, formStyles, largeInputStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import LargeInput from './LargeInput';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { checkoutActions } from '../redux/checkout';

export default function CompanySizeForm() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const numberOfSeats = useAppSelector(
    (state) => state.checkout.checkoutData.numberOfSeats,
  );

  const coupon = useAppSelector((state) => state.checkout.checkoutData.coupon);

  const [showEmployeesSuffix, setShowEmployeesSuffix] = useState(
    numberOfSeats > 0,
  );
  const [numberOfEmployees, setNumberOfEmployees] = useState(
    numberOfSeats > 0 ? numberOfSeats.toString() : '10',
  );
  const [numberOfEmployeesError, setNumberOfEmployeesError] = useState('');

  const onContinuePressed = useCallback(async () => {
    dispatch(
      checkoutActions.setCheckoutData({
        numberOfSeats: parseInt(numberOfEmployees),
      }),
    );

    navigate(
      `/sme/3?numberOfEmployees=${numberOfEmployees}${
        coupon ? `&coupon=${coupon}` : ''
      }`,
    );
  }, [coupon, dispatch, navigate, numberOfEmployees]);

  const onNumberOfEmployeesChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;
      const parsed = Number(value);

      setNumberOfEmployees(value);

      if ((value !== '' && Number.isNaN(parsed)) || parsed <= 0) {
        setNumberOfEmployeesError('Invalid number of employees');
      } else {
        setNumberOfEmployeesError('');
      }
    },
    [],
  );

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={[smeStyles.content, { maxWidth: '576px' }]}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Typography variant={'h1'}>What’s your company size?</Typography>

        <Box sx={formStyles.container}>
          <LargeInput
            placeholder="100 employees"
            sx={{
              ...largeInputStyles.countryInput,
              ...(numberOfEmployeesError !== ''
                ? largeInputStyles.errorCountryInput
                : {}),
            }}
            value={`${numberOfEmployees}${
              showEmployeesSuffix ? ' employees' : ''
            }`}
            onChange={onNumberOfEmployeesChanged}
            onBlur={() => {
              if (numberOfEmployees !== '') {
                setShowEmployeesSuffix(true);
              }
            }}
            onFocus={() => setShowEmployeesSuffix(false)}
            error={numberOfEmployeesError !== ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Box>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          sx={{
            alignSelf: 'center',
            margin: '20px 0px 40px',
          }}
          onClick={onContinuePressed}
          disabled={numberOfEmployeesError !== '' || numberOfEmployees === ''}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Fade>
  );
}
