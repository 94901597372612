import { Fade, Box, Stack, Typography, OutlinedInput } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../assets/assets';
import { useMobile } from '../common/hooks';
import { formStyles, smeStyles } from '../styles/sme';
import { FADE_TIMEOUT } from '../styles/stylesUtils';
import { LoadingButton } from '@mui/lab';
import { debounce } from 'lodash';
import { useGetPlansQuery, useGetPriceMutation } from '../redux/api';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { checkoutActions } from '../redux/checkout';
import { summaryFormStyles } from './styles.ts/SummaryForm.styles';
import { pickYourPlanStyles } from './styles.ts/PickYourPlan.styles';

export default function SummaryForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const reduxNumberOfSeats = useAppSelector(
    (state) => state.checkout.checkoutData.numberOfSeats,
  );

  const [numberOfSeats, setNumberOfSeats] = useState(
    reduxNumberOfSeats > 0 ? reduxNumberOfSeats.toString() : '',
  );
  const [loadingPrices, setLoadingPrices] = useState(false);

  const [getPrice, { data }] = useGetPriceMutation();

  const reduxPlan = useAppSelector(
    (state) => state.checkout.checkoutData.planId,
  );
  const coupon = useAppSelector((state) => state.checkout.checkoutData.coupon);

  const { data: plans } = useGetPlansQuery(coupon);

  const plan = useMemo(() => {
    if (!plans) {
      return undefined;
    }

    return plans.plans.find((item) => item.id === reduxPlan);
  }, [plans, reduxPlan]);

  const validNumberOfSeats = useMemo(() => {
    if (!plan) {
      return false;
    }
    const parsed = parseInt(numberOfSeats);

    return !isNaN(parsed) && parsed >= plan?.min_number_of_seats;
  }, [numberOfSeats, plan]);

  const onContinuePressed = useCallback(async () => {
    if (validNumberOfSeats) {
      dispatch(
        checkoutActions.setCheckoutData({
          numberOfSeats: parseInt(numberOfSeats),
        }),
      );
      navigate('/sme/5');
    }
  }, [dispatch, navigate, numberOfSeats, validNumberOfSeats]);

  const mobile = useMobile();

  const updatePrice = useMemo(() => {
    return debounce(
      (numberOfSeats: number) => {
        getPrice({ planId: plan ? plan.id : 1, numberOfSeats, coupon })
          .unwrap()
          .finally(() => setLoadingPrices(false));
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    );
  }, [coupon, getPrice, plan]);

  const onNumberSeatsChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNumberOfSeats(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (validNumberOfSeats) {
      setLoadingPrices(true);
      updatePrice(parseInt(numberOfSeats));
    }
  }, [numberOfSeats, updatePrice, validNumberOfSeats]);

  if (!plans) {
    return null;
  }

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        sx={[smeStyles.content, { maxWidth: '576px' }]}
        justifyContent={'space-between'}
        alignSelf={'center'}
        alignItems={'center'}
      >
        <Stack gap={'20px'}>
          <Typography variant={'h1'}>Summary</Typography>

          <Typography variant={'headerSubtitle'}>
            You commit to be charged monthly for this amount of members.
            <br />
            Number of seats can be increased anytime in the Dashboard
          </Typography>
        </Stack>

        <Box sx={[formStyles.container, { gap: '20px' }]}>
          <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
            <Typography sx={summaryFormStyles.numberOfSeats}>
              Number of seats to start:
            </Typography>

            <OutlinedInput
              type="number"
              value={numberOfSeats}
              sx={summaryFormStyles.numberOfSeatsInput}
              onChange={onNumberSeatsChanged}
              error={!validNumberOfSeats}
            />
          </Stack>

          <Stack
            sx={[
              summaryFormStyles.container,
              mobile ? summaryFormStyles.containerMobile : {},
            ]}
            direction={mobile ? 'row-reverse' : 'row'}
          >
            <Stack
              sx={{ width: '254px', padding: '0px 40px' }}
              justifyContent={'center'}
              alignItems="flex-start"
            >
              <Typography sx={summaryFormStyles.membership}>
                MEMBERSHIP
              </Typography>
              <Typography sx={summaryFormStyles.oneOffPayment}>
                Subscription
              </Typography>

              {data !== undefined && (
                <>
                  <Typography sx={pickYourPlanStyles.vatText}>
                    {reduxPlan === 1 ? 'Piki' : 'Paka'} plan:{' '}
                    {(data.price - data.vat).toFixed(2)}£
                  </Typography>

                  <Typography sx={pickYourPlanStyles.vatText}>
                    VAT: {data.vat.toFixed(2)}£
                  </Typography>

                  <Typography
                    sx={[
                      summaryFormStyles.sum,
                      mobile ? summaryFormStyles.sumMobile : {},
                      { marginBottom: '0px' },
                    ]}
                  >
                    Total: {data.price.toFixed(2)}£
                  </Typography>
                </>
              )}
            </Stack>

            <Stack
              sx={{ flexGrow: 1 }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={images.summary.summaryAvatar}
                sx={summaryFormStyles.image}
              />
            </Stack>
          </Stack>

          <Box component={'img'} src={images.cardForm.poweredByStripe} />
        </Box>

        <LoadingButton
          variant="contained"
          endIcon={<Box component={'img'} src={images.button.arrowRight} />}
          sx={{
            alignSelf: 'center',
            margin: '20px 0px 40px',
          }}
          onClick={onContinuePressed}
          disabled={!validNumberOfSeats || loadingPrices}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Fade>
  );
}
